import React, { useState } from 'react';
import axios from 'axios';
import "./add-form.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { handleCategoryChange } from "./formUtils";

const AddForm = () => {
    const initialState = {
        image1: null,
        image2: null,
        image3: null,
        date: '',
        name: '',
        dimensionH: '',
        dimensionW: '',
        dimensionD: '',
        price: '',
        quantity: '',
        description: '',
        category: '',
        subCategory: '',
        doorPanels: '',
        shipping: '0',
    };

    const [validated, setValidated] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [subCategories, setSubCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(initialState);
    const [imagePreviews, setImagePreviews] = useState({
        imageURL1: null,
        imageURL2: null,
        imageURL3: null,
    });

    const resetForm = () => {
        setSelectedCategory('');
        setSubCategories([]);
        setSelectedSubCategory([]);
        setImagePreviews({
            imageURL1: null,
            imageURL2: null,
            imageURL3: null,
        });
        setFormData(initialState);
    };

    const handleCategorySelection = (e) => {
        const selectedCategoryValue = e.target.value;
        setSelectedCategory(selectedCategoryValue);
        handleCategoryChange(selectedCategoryValue, setSubCategories, setSelectedSubCategory);
        setFormData({
            ...formData,
            category: selectedCategoryValue,
            subCategory: ''
        });
    };

    const handleSubCategorySelection = (e) => {
        const selectedSubCategoryValue = e.target.value;
        setSelectedSubCategory(selectedSubCategoryValue);
        setFormData({
            ...formData,
            subCategory: selectedSubCategoryValue
        });
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? (checked ? "1" : "0") : value;

        setFormData({
            ...formData,
            [name]: newValue,
        });
    };

    const handleFileInputChange = (e, index) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const image = new Image();
            image.src = event.target.result;

            image.onload = () => {
                setFormData({
                    ...formData,
                    [`image${index}`]: file
                });

                setImagePreviews((prevImagePreviews) => ({
                    ...prevImagePreviews,
                    [`imageURL${index}`]: event.target.result,
                }));
            };
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const form = e.currentTarget;
        if (form.checkValidity() === true) {
            try {
                const response = await axios.post('https://rockycoastlabs.xyz/upload.php', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                alert('Inventory item was successfully added');
                resetForm();
                setLoading(false);
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            alert('Form data is invalid. Please check the fields.');
            setLoading(false);
        };
    };

    return (
        <>
            {loading &&
                <div className='placeholder-zone-loading d-flex justify-content-center'>
                    <div className='spinner '></div>
                </div>} 
            {!loading &&
                <Form noValidate validated={validated} onSubmit={handleSubmit} encType='multipart/form-data'>
                    <Row className='mt-5'>
                        <Col>
                            {[1, 2, 3].map(index => (
                                <Col className='mx-5' key={index}>
                                    <Form.Label>{`Image ${index} (1500px X 1000px)`}</Form.Label>
                                    <br />
                                    <Form.Group controlId={`formFile${index}`} className="mb-3">
                                        {imagePreviews[`imageURL${index}`] && (
                                            <img
                                                src={imagePreviews[`imageURL${index}`]}
                                                alt={`Preview ${index}`}
                                                style={{ width: '150px', height: '100px' }}
                                            />
                                        )}
                                        <Form.Control type="file" name={`imageURL${index}`} onChange={(e) => handleFileInputChange(e, index)} />
                                    </Form.Group>
                                </Col>
                            ))}
                        </Col>
                        <Col className='mx-5'>
                            <Row className='py-4'>
                                <Col>
                                    <FloatingLabel
                                        label="Name"
                                        className="mb-3">
                                        <Form.Control type="name" name='name' onChange={handleInputChange} required />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className='py-4'>
                                <Col>
                                    <FloatingLabel label="Price" className="mb-3">
                                        <Form.Control type="number" name='price' step="0.01" min="0" onChange={handleInputChange} required />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel label="Quantity" className="mb-3">
                                        <Form.Control type="number" name='quantity' onChange={handleInputChange} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className='py-4'>
                                <FloatingLabel label="Description" className="mb-3">
                                    <Form.Control
                                        name='description'
                                        onChange={handleInputChange}
                                        style={{ height: '100px', resize: 'none' }}
                                        type="text"
                                        as="textarea"
                                        rows={3}
                                        required />
                                </FloatingLabel>
                            </Row>
                            <Row className='py-4'>
                                <Col>
                                    <FloatingLabel label="Category">
                                        <Form.Select
                                            name='category'
                                            value={selectedCategory}
                                            onChange={handleCategorySelection}
                                            required>
                                            <option>Select</option>
                                            <option value="antiques">Antiques</option>
                                            <option value="doors">Doors</option>
                                            <option value="windows">Windows</option>
                                            <option value="lighting">Lighting</option>
                                            <option value="plumbing">Plumbing</option>
                                            <option value="hardware">Hardware</option>
                                            <option value="other">Other</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel label="Sub-Category">
                                        <Form.Select
                                            name="subCategory"
                                            value={selectedSubCategory}
                                            onChange={handleSubCategorySelection}
                                            disabled={!selectedCategory}
                                            required
                                        >
                                            <option>Select</option>
                                            {subCategories.map((subCategory, index) => (
                                                <option key={index} value={subCategory}>
                                                    {subCategory}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel label="Door Panels">
                                        <Form.Select
                                            name="doorPanels"
                                            onChange={handleInputChange}
                                            disabled={selectedCategory !== 'doors'}
                                        >
                                            <option>Select</option>
                                            <option value={1}>
                                                One
                                            </option>
                                            <option value={2}>
                                                Two
                                            </option>
                                            <option value={3}>
                                                Three
                                            </option>
                                            <option value={4}>
                                                Four
                                            </option>
                                            <option value={5}>
                                                Five
                                            </option>
                                            <option value={6}>
                                                Six
                                            </option>
                                            <option value={7}>
                                                Seven +
                                            </option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className='py-4'>
                                <Col>
                                    <Form.Check
                                        type="checkbox"
                                        label="Shipping Available?"
                                        name="shipping"
                                        checked={formData.shipping === "1"}
                                        onChange={handleInputChange}
                                    />
                                </Col>

                                <Col>
                                    <FloatingLabel controlId="floatingInput" label="Width" className="mb-1">
                                        <Form.Control type="number" step="0.0001" min="0" name='dimensionW' onChange={handleInputChange} />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel controlId="floatingInput" label="Height" className="mb-1">
                                        <Form.Control type="number" step="0.0001" min="0" name='dimensionH' onChange={handleInputChange} />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel controlId="floatingInput" label="Depth" className="mb-1">
                                        <Form.Control type="number" step="0.0001" min="0" name='dimensionD' onChange={handleInputChange} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Col>
                    </Row >
                    <Row className='justify-content-end me-5 pt-5'>
                        <Button className='clear-btn me-5' variant="secondary" onClick={resetForm}><h3>Clear</h3></Button>
                        <Button className='post-btn' variant="primary" type='submit'><h3>Post to Website</h3></Button>
                    </Row>
                </Form >
            }
        </>
    )
}

export default AddForm

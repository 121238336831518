import React from 'react';
import "./dashboardnav.css";
import { Button, Stack } from 'react-bootstrap';
import axios from 'axios';
import add from "../../assets/img/add.svg";
import edit from "../../assets/img/edit.svg";


const DashboardNav = ({ onDashboardChange }) => {

  const handleDownloadInventoryClick = () => {
    axios.get('https://rockycoastlabs.xyz/getAll.php')
      .then(response => {
        console.log(response.data)
        downloadData(response.data, "Inventory");
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  };

  const handleDownloadArchiveClick = () => {
    axios.get('https://rockycoastlabs.xyz/getArchived.php')
      .then(response => {
        console.log(response.data)
        downloadData(response.data, "Archived");
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  };

  const downloadData = (data, name) => {
    // Format data to CSV
    const csvContent = formatDataToCSV(data);

    // Create Blob and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    const currentDate = new Date().toLocaleDateString().replaceAll('/', '-');
    a.download = `${name}_${currentDate}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const formatDataToCSV = (data) => {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));

    data.forEach(row => {
      const values = headers.map(header => row[header]);
      csvRows.push(values.join(','));
    });

    return csvRows.join('\n');
  };

  return (
    <div className='mx-5'>
      <Stack className='d-flex justify-content-between' direction='horizontal' gap={5}>
        <div>
          <button className='tab-btn' onClick={() => onDashboardChange(true)}>
            <img src={add} alt='Add' />
            <h2>Add</h2>
          </button>
          <button className='ms-5 tab-btn' onClick={() => onDashboardChange(false)}>
            <img src={edit} alt='Edit' />
            <h2>Edit</h2>
          </button>
        </div>
        <div>
          <Button className='ms-3 px-4 py-3 download' onClick={handleDownloadInventoryClick}><h5>Download Inventory</h5></Button>
          <Button className='ms-5 px-4 py-3 download' onClick={handleDownloadArchiveClick}><h5>Download Archive</h5></Button>
        </div>
      </Stack>
    </div>
  )
}

export default DashboardNav

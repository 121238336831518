import React, { useState } from 'react';
import axios from 'axios';
import "./editdashboardcell.css";
import { Card, Stack, Col, Button, Modal } from 'react-bootstrap';
import EditModal from '../edit-modal/EditModal';

const EditDashboardCell = ({ item }) => {
    const [showModal, setShowModal] = useState(false);
    const [showEdit, setShowEdit] = useState(false)


    const handleArchive = async () => {
        try {
            const response = await axios.post('https://rockycoastlabs.xyz/archive.php', item, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log(response.data);
            alert('Inventory item was successfully archived');
            window.location.reload();
        } catch (error) {
            console.error('Error:', error);
        }

    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleEditClick = () => {
        console.log("edit button clicked")
        setShowEdit(true)
    };

    const handleCloseEditModal = () => {
        setShowEdit(false)
    };

    return (
        <div className='edit-inventory-cell py-5 px-4'>
            <Card>
                <Card.Img variant="top" src={`https://rockycoastlabs.xyz/${item.image_path1}`} />
                <Card.Body>
                    <Card.Text className='cell-text'>
                        <Stack className='justify-content-between d-flex' direction="horizontal">
                            <div className="inventory-name-text pe-1"><strong>{item.name}</strong></div>
                            <div>ID: #{item.id}</div>
                        </Stack>
                        <Stack className='justify-content-between d-flex' direction="horizontal">
                            <div className="date"><strong>Created:</strong><br/>{item.date.split(/(\s+)/)[0]}</div>
                            {(item.date_modified !== '0000-00-00' && item.date_modified) && (
                                <div className="date"><strong>Modified:</strong><br/>{item.date_modified}</div>
                            )}
                     </Stack>
                    </Card.Text>
                </Card.Body>
                <Col className='cell-btns pt-2'>
                    <Button variant="danger" className='me-3' onClick={handleOpenModal}>Archive</Button>
                    <Button className='ms-3' onClick={handleEditClick}>Edit</Button>
                </Col>
            </Card>

            {/* Archive Modal */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Archive Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to archive this item?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant='danger' onClick={handleArchive}>
                        Archive
                    </Button>
                </Modal.Footer>
            </Modal>


            <EditModal item={item} show={showEdit} onHide={handleCloseEditModal} />
        </div>
    )
}

export default EditDashboardCell

import React, { useState } from 'react';
import './editdashboardcontainer.css';
import EditDashboardCell from '../edit-dashboard-cell/EditDashboardCell';
import ArchivedCell from '../archived-cell/ArchivedCell';
import { Container, Row, Stack, Button } from 'react-bootstrap';

const EditDashboardContainer = ({ inventoryData, category, subCategory }) => {
  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);

  const maxPages = Math.ceil(inventoryData.length / itemsPerPage);

  const renderDashboardCells = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const slicedItems = inventoryData.slice(startIndex, endIndex);

    if (category === 'Archived') {
      return slicedItems.map(item => (
        <ArchivedCell key={item.id} item={item} />
      ));
    } else {
      return slicedItems.map(item => (
        <EditDashboardCell key={item.id} item={item} />
      ));
    }
  };

  const handleNextPage = () => {
    if (currentPage < maxPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
      <Stack>
        <Stack>
          <div className='items-container-heading'>
            <h1>{category}</h1>
            {subCategory !== 'antique' && (
              <h5 className='pb-1 pt-2'>{subCategory}</h5>
            )}
          </div>
          <div className='inventory-cell-container'>
            <Row xs={1} sm={2} lg={3} xl={4}>
              {renderDashboardCells()}
            </Row>
          </div>
        </Stack>
        <Stack className='justify-content-center my-5' direction='horizontal'>
          <Button className='mx-4' variant="secondary" onClick={handlePrevPage} disabled={currentPage === 1}>
            Previous
          </Button>
          <span>{`Page ${currentPage} of ${maxPages}`}</span>
          <Button className='mx-4' variant="secondary" onClick={handleNextPage} disabled={currentPage === maxPages}>
            Next
          </Button>
        </Stack>
      </Stack>
  );
};

export default EditDashboardContainer;